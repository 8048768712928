import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { validatePayment } from '../debtUtilities';
import useCreadyApi from '../utils/Api';
import ClientDropdown from './ClientDropdown';
import DropdownComponent from './DropdownComponent';
import { fetchOriginalValueById, transformArrayForDropdown } from '../utilities';

export default function WithdrawDebtModal({open, close, cancel, debtId}){
    const [comment, setComment] = useState("");
    const api = useCreadyApi();
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [closing, setClosing] = useState(false);

    async function send(){

        await sendWithdrawal();
      }

    async function sendWithdrawal(){
      try {
        setSending(true);
        let res = await api.debts.withdraw({
            id: debtId,
            comment: comment
        });
    } catch (error) {
        console.error("Failed to withdraw debt", error);
    } finally {
      setSending(false);
      setSent(true);
    }
    }

    function handleClose(){
      setClosing(true);
      close();
      setComment("");
      setClosing(false);
    }

    function cancelHandle(){
        cancel();
    }

    if(closing){
      return <></>
    }
    return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={cancelHandle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                    {!sending && !sent && (
                      <>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h2" className="text-xl font-semibold leading-6 text-gray-900 pb-4">
                      Återkalla ärende
                    </Dialog.Title>
                    <div className="mt-2">
                    <div>
      <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
        Ev. Kommentar
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          id="comment"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
          defaultValue={''}
        />
      </div>
    </div>
                    </div>
                  </div>
                  </>
                          )}
                          {sending && !sent && (<span>Återkallar ärende..</span>)}
                </div>
                {!sending && !sent && (
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-center hover:text-red bg-red align-middle transition-all duration-100 ease-in-out hover:bg-transparent border-2 border-red border-solid rounded-md cursor-pointer text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:col-start-2"
                    onClick={() => send()}
                  >
                    Återkalla
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => cancelHandle()}
                  >
                    Avbryt
                  </button>
                </div>
                )}
                {!sending && sent && (
                  <>
                  <span className='mb-4'>Vi har tagit emot din begäran</span>
                                    <button
                                    type="button"
                                    className="mt-10 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                                    onClick={() => handleClose()}
                                    >
                                    Stäng
                                  </button>
                                    </>
                )
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    )
}