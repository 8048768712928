import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "./Header";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DebtList from "../pages/DebtList";
import Dashboard from "../pages/Dashboard";
import ErrorPage from "./NotFound";
import Debt from "../pages/Debt";
import Invoice from "../pages/Invoice";
import DebtCreation from "../pages/DebtCreation";
import useCreadyApi from "../utils/Api";
import InvoiceList from "../pages/InvoiceList";



export default function Main() {
  const [userClients, setuserClients] = useState(null);
  const [currentClient, setCurrentClient] = useState([]);
  const { isAuthenticated } = useAuth0();
  const api = useCreadyApi();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.clients.list();
        setuserClients(data);
        localStorage.setItem('userClients', JSON.stringify(data));
        localStorage.setItem('hasMultipleClients', data.length > 1);
        var cli = localStorage.getItem('currentClient');
        if (cli == null) {
          localStorage.setItem('currentClient', JSON.stringify(data[0]));
          setCurrentClient(data[0]);

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const router = createBrowserRouter([
    {
      path: "/",
      element: <DebtList />,
      errorElement: <ErrorPage/>,
    },
    {
      path: "debts/:id",
      element: <Debt />,
      errorElement: <ErrorPage/>,
    },
    {
      path: "invoice/:id",
      element: <Invoice />,
      errorElement: <ErrorPage/>,
    },
    {
      path: "invoices",
      element: <InvoiceList />,
      errorElement: <ErrorPage/>,
    },
    {
      path: "create",
      element: <DebtCreation />,
      errorElement: <ErrorPage/>,
    },
  ]);

    if (isAuthenticated && window.localStorage.getItem('currentClient')) {


      
      return (
        <div>
          <Header></Header>
          <div className="pt-8">
            <RouterProvider router={router} />
          </div>
        </div>
      );
    } else {
      return <h1>Logging in</h1>;
    }
};