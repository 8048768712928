import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { validatePayment } from '../debtUtilities';
import useCreadyApi from '../utils/Api';
import ClientDropdown from './ClientDropdown';
import DropdownComponent from './DropdownComponent';
import { fetchOriginalValueById, transformArrayForDropdown } from '../utilities';

export default function AddPaymentModal({open, close, cancel, debtId, invoices}){
    const [payment, setPayment] = useState({amount: 0, tranctionDate: Date.now, invoiceNumber: invoices[0].id});
    const [validationResult, setValidationResult] = useState({ valid: false, messages: [] });
    const api = useCreadyApi();
    const [adding, setAdding] = useState(false);
    const [added, setAdded] = useState(false);

  function handleInvoiceChange(invoice){
    handlePaymentChange("invoiceNumber", invoice.id);

  }

    const handlePaymentChange = (id, value) => {
        setPayment(currentPayment => ({
          ...currentPayment,
          [id]: value
        }));
      };
      
    async function add(){
        let validateResult = validatePayment(payment);
        setValidationResult(validateResult);
        if(!validateResult.valid){
            return;
        } 
        await sendPayment();
        // close(payment);
    }

    async function sendPayment(){
      try {
        setAdding(true);
        let res = await api.debts.addPayment({
            debtId: debtId, 
            amount: payment.amount, 
            transactionDate: payment.transactionDate,
            invoiceId: payment.invoiceNumber
        });
    } catch (error) {
        console.error("Failed to add payment:", error);
    } finally {
      setAdding(false);
      setAdded(true);
    }
    }

    function handleClose(){
      setAdded(false);
      close();
    }

    function cancelHandle(){
        cancel();
    }
    return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={cancelHandle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                    {!adding && !added && (
                      <>
                      <DropdownComponent data={transformArrayForDropdown(invoices, "id", "invoiceNumber")} onChange={handleInvoiceChange}></DropdownComponent>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h2" className="text-xl font-semibold leading-6 text-gray-900 pb-4">
                      Lägg till betalning
                    </Dialog.Title>
                    <div className="mt-2">
                    <div className="grid w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                            <div className='sm:col-span-2'>
                              <label htmlFor="amount" className="block text-sm font-medium leading-6 text-gray-900">
                                Belopp
                              </label>
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                  value={payment.amount}
                                  onChange={(e) => handlePaymentChange('amount', e.target.value)}
                                  type="number"
                                  name="amount"
                                  id={"amount"}
                                  required={true}
                                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
                                />

                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                                    SEK
                                  </span>
                                </div>
                              </div>
                              {validationResult.messages.filter(v => v.id === "amount").map((m) => {
                                return <span className='text-red-500'>{m.message}</span>
                              })}
                            </div>
                            <div className="sm:col-span-2">
                              <label htmlFor="transactionDate" className="block text-sm font-medium leading-6 text-gray-900">
                                Transaktionsdatum
                              </label>
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                </div>
                                <input
                                  value={payment.transactionDate}
                                  onChange={(e) => handlePaymentChange('transactionDate', e.target.value)}
                                  type="date"
                                  name="transactionDate"
                                  id={"transactionDate"}
                                  required={true}
                                  className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                                {validationResult.messages.filter(v => v.id === ("transactionDate")).map((m) => {
                                  return <span className='text-red-500'>{m.message}</span>
                                })}
                            </div>
                            <div className='sm:col-span-1'>
                            </div>
                          </div>
                    </div>
                  </div>
                  </>
                          )}
                          {adding && !added && (<span>Skapar betalning...</span>)}
                </div>
                {!adding && !added && (
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-center hover:text-blue-2 bg-blue-2 align-middle transition-all duration-100 ease-in-out hover:bg-transparent border-2 border-blue-2 border-solid rounded-md cursor-pointer text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:col-start-2"
                    onClick={() => add()}
                  >
                    Lägg till
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => cancelHandle()}
                  >
                    Avbryt
                  </button>
                </div>
                )}
                {!adding && added && (
                  <>
                  <span>Vi har tagit emot din betalning.</span>
                                    <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                    onClick={() => handleClose()}
                                    >
                                    Stäng
                                  </button>
                                    </>
                )
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    )
}