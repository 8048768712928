import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useRef } from 'react'
import { Disclosure, Menu, RadioGroup, Transition, Radio } from '@headlessui/react'
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/20/solid'
import { Pagination } from 'flowbite-react';
import { useState } from 'react';
import { useSearchParams, useNavigation } from 'react-router-dom';
import LoadingTable from '../components/LoadingTable';
import useCreadyApi from '../utils/Api';
import { formatNumberWithSeparators } from '../utilities';
import DropDown from '../components/Dropdown';
import DebtTable from '../components/DebtTable'
import InvoiceTable from '../components/InvoiceTable';


  export default function InvoiceList() {
    const availableSearchTypes= [{id: 0, name: "Fakturanummer"}, {id: 1, name: "Pnr/Org"}, {id: 2, name: "Gäldenär"}];
    const availableActiveStatus = [
      { name: 'Alla', id: -1},
      { name: 'Avslutade', id: 0 },
      { name: 'Aktiva', id: 1 }
    ]
    const availableMainStatus = [
      { name: 'Alla', id: -1},
      { name: 'Faktura', id: 0 },
      { name: 'Inkasso', id: 1 },
      { name: 'Kronofogden', id: 2 },
      { name: 'Efterbevakning', id: 3 }
    ]
    const availableUnderStatus = [
      { name: 'Alla', id: -1},
      { name: 'Faktura', id: 0},
      { name: 'Påminnelse', id: 1 },
      { name: 'Inkasso', id: 2 },
      { name: 'Avbetalningsplan', id: 3 },
      { name: 'Skuldsanering', id: 4 },
      { name: 'Betalningsförläggande', id: 5 },
      { name: 'Verkställighet', id: 6 },
      { name: 'Konkurs', id: 7 },
      { name: 'Bestriden', id: 8 },
      { name: 'Juridisk hantering', id: 9 },
    ]
    const availablePaymentStatus = [
      { name: 'Alla', id: -1},
      { name: 'Betald', id: 0 },
      { name: 'Obetald', id: 1 },
      { name: 'Delbetald', id: 2 },
    ]

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [userInteracted, setUserInteracted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [invoiceList, setInvoiceList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const inputRef = useRef(null);
    const api = useCreadyApi();
    const navigation = useNavigation();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [currentClient, setCurrentClient] = useState(window.localStorage.getItem('currentClient'));
    const [searchType, setSearchType] = useState(availableSearchTypes[0]);
    const [activeStatus, setActiveStatus] = useState(availableActiveStatus[2]);
    const [mainStatus, setMainStatus] = useState(availableMainStatus[0]);
    const [underStatus, setUnderStatus] = useState(availableUnderStatus[0]);
    const [paymentStatus, setPaymentStatus] = useState(availablePaymentStatus[0]);

      if(isFirstRender && !userInteracted){
        let params = getAllSearchParams();
        setSearchTerm(params.q ?? "");
          setCurrentPage(params.page ?? 1);
          setSearchType(getItemById(params.type ?? 0, availableSearchTypes) || availableSearchTypes[0]);
          setActiveStatus(getItemById(params.active ?? 1, availableActiveStatus) || availableActiveStatus[2]);
          setMainStatus(getItemById(params.main ?? -1, availableMainStatus)  || availableMainStatus[0]);
          setUnderStatus(getItemById(params.sub ?? -1, availableUnderStatus) || availableUnderStatus[0]);
          setPaymentStatus(getItemById(params.payment ?? -1, availablePaymentStatus) || availablePaymentStatus[0]);
          search();
          setIsFirstRender(false);
        }

    function getAllSearchParams(){
      let q = searchParams.get("q") ?? "";
      let type = searchParams.get("type") ?? 0;
      let page = searchParams.get("page") ?? 1;
      let active = searchParams.get("active");
      let main = searchParams.get("main");
      let sub = searchParams.get("sub");
      let payment = searchParams.get("payment");
      return { q: q, page: page, type: type, active: active, main: main, sub: sub, payment: payment };
    }
    
    function getItemById(id, list){
      var res = list.filter(a => a.id === parseInt(id))[0];
      return res ?? null;
    }

    useEffect(()=>{
    }, [searchTerm])
    async function search() {
      setIsLoading(true);
        try {
      let params = [];
      if(isFirstRender && !userInteracted){
        let searchParams = getAllSearchParams();
        if(searchParams.q == null){
          params = [{page: searchParams.page}];
      }else{
        params = [{page: searchParams.page},{ searchTerm: searchParams.q}, {searchType: searchParams.type}];
      }
      params.push(
        { activeStatus: searchParams.active ?? 1 },
        { receivableMainStatus: searchParams.main ?? -1 },
        { receivableSubStatus: searchParams.sub ?? -1 },
        { paymentStatus: searchParams.payment ?? -1 },
        );
      }else{
        
        if(searchTerm == null){
          params = [{page: currentPage}];
      }else{
        params = [{page: currentPage ?? 1},{ searchTerm: searchTerm }, {searchType: searchType.id ?? 0}];
      }
      params.push(
        { activeStatus: activeStatus.id },
        { receivableMainStatus: mainStatus.id },
        { receivableSubStatus: underStatus.id },
        { paymentStatus: paymentStatus.id },
        );
      }
      const responseData = await api.invoices.list(params);
      setInvoiceList(responseData);
      setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }

    
    const onFilterChange = (name, value) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(name, value);
      setSearchParams(searchParams.toString());
    }

    useEffect(()=> {
      if(!isFirstRender && userInteracted){

        search();
      }
    }, [currentPage, activeStatus, mainStatus, underStatus, paymentStatus, searchTerm])

  const handleSearch = () => {

    const inputValue = inputRef.current.value;
    onFilterChange('q', inputValue);
    setSearchTerm(inputValue);

  };
  const onPageChange = (page) => 
  {
    setUserInteracted(true);
    onFilterChange('page', page);
    setCurrentPage(page);
  }
  function searchTypeChanged(selected){
    onFilterChange('type', selected.id);
    setSearchType(selected);
  }
  function searchActiveChanged(selected){
    onFilterChange('active', selected.id);
    setActiveStatus(selected);
  }
  function searchMainStatusChanged(selected){
      setMainStatus(selected);
      onFilterChange('main', selected.id);
  }
  function searchUnderStatusChanged(selected){
    onFilterChange('sub', selected.id);
    setUnderStatus(selected);
  }
  function searchPaymentStatusChanged(selected){
    onFilterChange('payment', selected.id);
    setPaymentStatus(selected);
  }


  useEffect(() => {
    const handleClick = () => {
      setUserInteracted(true);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {/* <h1 className="text-base font-semibold leading-6 text-gray-900">Ärenden</h1> */}
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          </div>
        </div>
<div>
<div className="flex">
      <div className="w-full flex">
        <label htmlFor="search" className="sr-only">
          Search
        </label>


        <form onSubmit={(event) => {
  event.preventDefault(); // Prevent default form submission behavior
  handleSearch();
}}>
        <div className="relative flex">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
          <input
            ref={inputRef}
            id="search"
            name="search"
            className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
            placeholder={searchTerm ?? "T.ex. id eller namn" }
            type="search"
          />
        <button type='submit' className="ml-2 rounded bg-darkgrey px-2 py-1 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 transition-all duration-100 ease-in-out hover:bg-transparent border-2 border-darkgrey border-solid hover:text-darkgrey focus:ring-black-600 focus:ring-2 focus:ring-inset">
          Sök
        </button>
        </div>
        
</form>
<div className='ml-3 -mt-1'>

                    <DropDown values={availableSearchTypes} notifyParent={searchTypeChanged} className=""></DropDown>
</div>



      </div>
    </div>
    <div className='flex gap-4'>
      <div className='w-56 mt-4'>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium leading-6 text-gray-900">Ärendestatus</div>
      </div>
                    <DropDown values={availableActiveStatus} notifyParent={searchActiveChanged} className="" initial={activeStatus}></DropDown>

      </div>
      <div className='w-56 mt-4'>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium leading-6 text-gray-900">Huvudstatus</div>
      </div>
                    <DropDown values={availableMainStatus} notifyParent={searchMainStatusChanged} className="" initial={mainStatus}></DropDown>

      </div>
      <div className='w-56 mt-4'>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium leading-6 text-gray-900">Understatus</div>
      </div>
                    <DropDown values={availableUnderStatus} notifyParent={searchUnderStatusChanged} className="" initial={underStatus}></DropDown>

      </div>
      <div className='w-56 mt-4'>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium leading-6 text-gray-900">Betalstatus</div>
      </div>
                    <DropDown values={availablePaymentStatus} notifyParent={searchPaymentStatusChanged} className="" initial={paymentStatus}></DropDown>

      </div>
    </div>
</div>
<InvoiceTable debtList={invoiceList} isLoading={isLoading}></InvoiceTable>
        <div className='flex overflow-x-auto sm:justify-center pb-16 pt-8'>
        {!isLoading ?<Pagination
        layout="pagination"
        currentPage={currentPage}
        totalPages={invoiceList.total}
        onPageChange={onPageChange}
        previousLabel="Föregående"
        nextLabel="Nästa"
        showIcons
        theme={theme}
      /> : <></>}
        </div>
      </div>
    )
  }

  const theme = {
    "base": "",
    "layout": {
      "table": {
        "base": "text-sm text-gray-700 dark:text-gray-400",
        "span": "font-semibold text-gray-900 dark:text-white"
      }
    },
    "pages": {
      "base": "xs:mt-0 mt-2 inline-flex items-center -space-x-px",
      "showIcon": "inline-flex",
      "previous": {
        "base": "ml-0 rounded-l-lg border border-gray-300 bg-white py-2 px-3 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        "icon": "h-5 w-5"
      },
      "next": {
        "base": "rounded-r-lg border border-gray-300 bg-white py-2 px-3 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        "icon": "h-5 w-5"
      },
      "selector": {
        "base": "w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        "active": "bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white",
        "disabled": "opacity-50 cursor-normal"
      }
    }
  }