import React from "react";
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import PaymentTable from "../components/PaymentTable";
import PaymentList from "../components/PaymentList";
import FileList from "../components/FileList";
import { useEffect } from "react";
import { useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import {useParams} from 'react-router-dom';
import { formatNumberWithSeparators } from '../utilities';
import { cardio } from 'ldrs';
import useCreadyApi from "../utils/Api";
import ErrorPage from "../components/NotFound";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
export default function Invoice() {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [data, setData] = useState({});
    const params = useParams();
    const api = useCreadyApi();
    useEffect(() => {
      const fetchData = async () => {
        try {
         
  
      const responseData = await api.invoices.summary(params.id);
      if(responseData === null){
        setIsError(true);
      }else{
        setIsError(false);
        setData(responseData);
      }
      setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
  
    }, []);
    if(isLoading){

      cardio.register()
      return(
      <div className="h-screen flex items-center justify-center -mt-16">
      <l-cardio
        size="150"
        stroke="12"
        speed="3.2" 
        color="black" 
      ></l-cardio>
      </div>
      )
    }
    if(!isLoading && isError){
      return(
      <ErrorPage/>
          )
        }
      return (
        <>
      <main className="pb-16">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {/* Invoice summary */}
            <div className="lg:col-start-3 lg:row-end-1">
            <PaymentTable grid={data.grid}></PaymentTable>

            </div>

            {/* Invoice */}
            <div className="-mx-py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-8">
              <h2 className="text-2xl font-bold leading-7 text-black sm:truncate sm:text-3xl sm:tracking-tight">Faktura: {data.invoice.invoiceNumber}</h2>
              <dl className="grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4">
                </div>
                <div className="mt-2 sm:mt-0 sm:pl-4">
             
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900 underline">Fakturamottagare</dt>
                  <dd className="mt-2 text-gray-500">
                  <span className="font-medium text-gray-900">{data.recipient.name}</span>
                    {data.recipient.careOfAddress ? <br />: <></>}
                    {data.recipient.careOfAddress ? (data.recipient.careOfAddress) : <></>}
                    <br />
                    {data.recipient.street}
                    <br />
                    {data.recipient.zipCode} {data.recipient.city}
                    <br />
                    {data.recipient.identityNumber}
                    <br />
                    {data.recipient.email}
                    <br />
                    {data.recipient.phoneNumber}
                  </dd>
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900 underline">Status</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">Faktura aktiv: </span>{data.status.isCompleted ? 'Nej' : 'Ja'}<br/>
                    <span className="font-medium text-gray-900">Huvudstatus: </span>{data.status.statusValue}<br/>
                    <span className="font-medium text-gray-900">Understatus: </span>{data.status.substatus}<br/>
                    <span className="font-medium text-gray-900">Betalningsstatus: </span>{data.status.payment}<br/>
                    
                  </dd>
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900 underline">Fakturainfo</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">Fakturanummer: </span>{data.invoice.invoiceNumber}<br/>
                    <span className="font-medium text-gray-900">Fakturadatum: </span>{new Date(data.invoice.invoiceDate).toLocaleDateString("sv-SE")}<br/>
                    <span className="font-medium text-gray-900">Betalningsvillkor antal dagar: </span>{data.invoice.days}<br/>
                    <span className="font-medium text-gray-900">Förfallodatum: </span>{new Date(data.invoice.dueDate).toLocaleDateString("sv-SE")}<br/>
                    <span className="font-medium text-gray-900">Ränta: </span>{data.invoice.interest}%<br/>
                    <span className="font-medium text-gray-900">OCR: </span>{data.invoice.ocr}<br/>
                    <span className="font-medium text-gray-900">Ärendenummer: </span>{data.invoice.debtNumber}<br/>
                    
                  </dd>
                </div>
              </dl>
              <div className="pt-8">
        <table>
                <colgroup>
                  <col className="w-full" />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead className="border-b border-gray-200 text-gray-900 text-sm">
                  <tr>
                    <th scope="col" className="px-0 py-3 text-left font-semibold">
                      Artikelbeskrivning
                    </th>
                    <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                      Pris
                    </th>
                    <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                      Moms
                    </th>
                    <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                      Rabatt
                    </th>
                    <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                      Summa
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.invoice.invoiceRows.map((item) => (
                    <tr key={item.id} className="border-b border-gray-100">
                      <td className="max-w-0 px-0 py-5 align-top">
                        <div className="truncate text-sm text-gray-900">{item.description}</div>
                        <div className="truncate text-gray-500 text-sm">Antal: {item.quantity}</div>
                      </td>
                      <td className="hidden text-sm py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                        {formatNumberWithSeparators(item.pricePerPiece)}
                      </td>
                      <td className="hidden text-sm py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                        {item.vatPercent}%
                      </td>
                      <td className="py-5 text-sm pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                        {formatNumberWithSeparators(item.discount)}
                      </td>
                      <td className="py-5 text-sm pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                        {formatNumberWithSeparators(item.total)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="row" className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden">
                      
                    </th>
                    <th
                      scope="row"
                      colSpan={4}
                      className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
                    >
                      Exkl. moms
                    </th>
                    <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
                        {formatNumberWithSeparators(data.invoice.totalExcludingVat)}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
                      Totalt moms
                    </th>
                    <th
                      scope="row"
                      colSpan={4}
                      className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                    >
                      Moms
                    </th>
                    <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
                        {formatNumberWithSeparators(data.invoice.totalVat)}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="pt-4 font-semibold text-gray-900 sm:hidden">
                      Total
                    </th>
                    <th
                      scope="row"
                      colSpan={4}
                      className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                    >
                      Totalt
                    </th>
                    <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                      {formatNumberWithSeparators(data.invoice.total)}
                    </td>
                  </tr>
                </tfoot>
              </table>
              </div>
            </div>

            <div className="lg:col-start-3 pl-2">
              {/* Activity feed */}
              <h2 className="text-sm font-semibold leading-6 text-gray-900">Åtgärder</h2>
              <ul role="list" className="mt-6 space-y-6">
                {data.activities.map((activityItem, activityItemIdx) => (
                  <li key={activityItem.id} className="relative flex gap-x-4">
                    <div
                      className={classNames(
                        activityItemIdx === data.activities.length - 1 ? 'h-6' : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center'
                      )}
                    >
                      <div className="w-px bg-gray-200" />
                    </div>
                      <>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                          
                        </div>
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-medium text-gray-900">cready</span>{' '} -
                          <span className="font-medium text-gray-900"> {activityItem.name}</span>.
                        </p>
                        <time
                          dateTime={activityItem.days}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {new Date(activityItem.date).toLocaleDateString("sv-SE")}
                        </time>
                      </>
                  </li>
                ))}
              </ul>

<FileList documents={data.documents}/>
            </div>
          </div>
        </div>
        <div className="pl-8 pr-16 pt-8">
        <div className="shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-8 xl:pb-20 xl:pt-8">
        
        {/* <PaymentList payments={data.payments}/> */}
</div>
</div>
      </main>
    </>
      );
};