import React from "react";
import { DocumentIcon } from '@heroicons/react/20/solid';
import useCreadyApi from "../utils/Api";

export default function FileList(data) {
  const api = useCreadyApi();

  async function download(id){

      const blob = await api.file.getFileById(id);

      const blobUrl = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = 'invoice.pdf'; // You can specify the filename here
      document.body.appendChild(downloadLink);

      downloadLink.click();

      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(downloadLink);
    
  }
    if (data.documents.length == 0) {
        return <div>Inga dokument</div>;
      }
      return (
        <div className="py-6">
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">

              {data.documents.map((file) => (
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">{file.filename}</span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="#" className="text-sm font-semibold text-blue-2 underline hover:no-underline" onClick={() => download(file.externalId)}>
                      Ladda ner
                    </a>
                  </div>
                </li>
              ))}
              </ul>
            </dd>
          </div>
      );
};