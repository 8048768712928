
import { formatNumberWithSeparators } from '../utilities'
export default function PaymentTable(data) {
  return (
  

<div className="relative overflow-x-auto w-[500px] shadow-sm ring-1 ring-gray-900/5 rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 text-right">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    
                </th>
                <th scope="col" className="px-6 py-3">
                Ursprungligt
                </th>
                <th scope="col" className="px-6 py-3">
                Inbetalt
                </th>
                <th scope="col" className="px-6 py-3">
                Resterande
                </th>
            </tr>
        </thead>
        <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
                Kapital
                </th>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.capital.initial)}
                </td>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.capital.paid)}
                </td>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.capital.remaining)}
                </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
                Ränta
                </th>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.interest.initial)}
                </td>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.interest.paid)}
                </td>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.interest.remaining)}
                </td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
                Kostnader
                </th>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.costs.initial)}
                </td>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.costs.paid)}
                </td>
                <td className="px-6 py-4">
                {formatNumberWithSeparators(data.grid.costs.remaining)}
                </td>
            </tr>
            <tr className="bg-white dark:bg-gray-800 border-t-2 border-black-200">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-left">
                Totalt
                </th>
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {formatNumberWithSeparators(data.grid.total.initial)}
                </td>
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {formatNumberWithSeparators(data.grid.total.paid)}
                </td>
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {formatNumberWithSeparators(data.grid.total.remaining)}
                </td>
            </tr>
        </tbody>
    </table>
</div>

  );
}
