  import {formatNumberWithSeparators} from '../utilities'
import AddPaymentButton from './AddPaymentButton';
  export default function PaymentList(data) {
    if (data.payments.length == 0) {
      return (
      <>
      <div>Inga betalningar</div>
      <AddPaymentButton invoices={data.invoices}></AddPaymentButton>
      </>
)
    }
    return (
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Betalningar</h1>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className='flex justify-end px-8'>
              <AddPaymentButton invoices={data.invoices}></AddPaymentButton>
            </div>
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Belopp
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Transaktionsdatum
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Bokföringsdatum
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">
                      Kapital
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">
                      Ränta
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">
                      Kostnader
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">
                      Överinbetalt
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Bankkonto
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data.payments.map((payment) => (
                    <tr key={payment.id} className="even:bg-gray-50">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {payment.amount}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Date(payment.transactionDate).toLocaleDateString("sv-SE")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Date(payment.bookingDate).toLocaleDateString("sv-SE")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{formatNumberWithSeparators(payment.capital)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{formatNumberWithSeparators(payment.interest)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{formatNumberWithSeparators(payment.costs)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{formatNumberWithSeparators(payment.overpaidAmount)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{payment.bankAccount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
  