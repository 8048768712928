const config = {
  development: {
    apiBaseUrl: 'https://localhost:44332/api',
    auth0: {
      domain: "dev-jj0roggv.us.auth0.com",
      clientId: "kJsiC5UYzs3n68mckNgKu1CHnimQ63s1",
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://cready.se",
      },
      useRefreshTokens: true,
      cacheLocation: "localstorage"
    }
  },
  test: {
    apiBaseUrl: 'https://api.alfafi.se/api',
    auth0: {
      domain: "dev-cfnpfftkofmtaps6.us.auth0.com",
      clientId: "ABzuMcAUIVZSrpJWrYcoXDvKJnOBEhhg",
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://portal.cready.se",
      },
      useRefreshTokens: true,
      cacheLocation: "localstorage"
    }
  },

  production: {
    apiBaseUrl: 'https://api.vizion.se/api',
    auth0: {
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
      useRefreshTokens: true,
      cacheLocation: "localstorage"
    }
  }
};

export default config;