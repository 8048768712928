import { useAuth0 } from '@auth0/auth0-react';
import config from '../config';

const useCreadyApi = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const environment = process.env.REACT_APP_NODE_ENV || 'development';
  const apiUrl = config[environment].apiBaseUrl;

  const createHeaders = async () => {
    const token = await getAccessTokenSilently();
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  };

  
  const get = async (endpoint) => {
    try {
      const headers = await createHeaders();
      const response = await fetch(`${apiUrl}/${endpoint}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
        logout();
    }
  };

  const getWithId = async (endpoint, id) => {
    try {
      const headers = await createHeaders();
      const response = await fetch(`${apiUrl}/clients/${JSON.parse(window.localStorage.getItem('currentClient')).id}/${endpoint}/${id}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error in GET request for ${endpoint} with ID ${id}:`, error);
      throw error;
    }
  };

  const getFileById = async (endpoint, id) => {
    try {
      const headers = await createHeaders();
      const response = await fetch(`${apiUrl}/clients/${JSON.parse(window.localStorage.getItem('currentClient')).id}/${endpoint}/${id}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.blob();
      return data;
    } catch (error) {
      console.error(`Error in GET request for ${endpoint} with ID ${id}:`, error);
      throw error;
    }
  };
  const getWithClient = async (endpoint, id) => {
    try {
      const headers = await createHeaders();
      const response = await fetch(`${apiUrl}/clients/${JSON.parse(window.localStorage.getItem('currentClient')).id}/${endpoint}`, {
        method: 'GET',
        headers,
      });
      if(response.status === 404){
        return null;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error in GET request for ${endpoint} with ID ${id}:`, error);
      throw error;
    }
  };

  const getWithParams = async (endpoint, paramsArray) => {
    try {
      const headers = await createHeaders();

      const queryString = paramsArray
        .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`).join('&'))
        .join('&');

      const response = await fetch(`${apiUrl}/clients/${JSON.parse(window.localStorage.getItem('currentClient')).id}/${endpoint}?${queryString}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error in GET request for ${endpoint} with params:`, error);
      throw error;
    }
  };

  const post = async (endpoint, data) => {
    try {
      const headers = await createHeaders();
      const response = await fetch(`${apiUrl}/clients/${JSON.parse(window.localStorage.getItem('currentClient')).id}/${endpoint}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {

      console.error('Error in POST request:', error);
      throw error;
    }
  };

  const api = {
    clients: {
      list: async () => get('clients'),
      summary: async () => getWithClient('summary'),
    },
    debts: {
      getById: async (id) => getWithId('debt', id),
      list: async (params) => getWithParams('debt', params),
      chats: async (id) => getWithClient('debt/' + id + '/chat'),
      summary: async (id) => getWithClient('debt/' + id + '/summary'),
      addChatMessage: async (id, body) => post('debt/' + id + '/chat', body),
      create: async (body) => post('debt', body),
      addPayment: async (body) => post('debt/payment', body),
      withdraw: async (body) => post('debt/withdraw', body),
    },
    invoices:{
      getById: async (id) => getWithId('invoice', id),
      list: async (params) => getWithParams('invoices', params),
      summary: async (id) => getWithClient('invoices/' + id + '/summary')
    },
    file:{
      getFileById: async(id) => getFileById('document',id),
    },
    notification: {
      list: async (id) => getWithClient('notification/')
    }
  };

  return api;
};

export default useCreadyApi;
