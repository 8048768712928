export default function ErrorPage () {
    return (
      <>
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-indigo-600">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Sidan kan inte hittas</h1>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a href="mailto:info@cready.se" className="text-sm font-semibold text-gray-900">
                Kontakta support <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </main>
      </>
    )
  }
  