import { useEffect, useState } from "react";
import AddPaymentModal from "./AddPaymentModal";
import useCreadyApi from "../utils/Api";
import { cardio } from 'ldrs';
import { useNavigate, useParams } from 'react-router-dom';

export default function AddPaymentButton({invoices}) {
    const [open, setOpen] = useState(false);
    let { id } = useParams();
    cardio.register()

    const api = useCreadyApi();
    const navigate = useNavigate();

    async function close() {
        setOpen(false);
        
    }

    function cancel() {
        setOpen(false);
    }

    if(!invoices || invoices.length === 0){
        return (<></>)
    }

    return (
        <>
            <button onClick={() => setOpen(true)}
                    type="button"
                    className="block px-6 py-2.5 mb-10 text-sm font-semibold text-center hover:text-blue-2 bg-blue-2 align-middle transition-all duration-100 ease-in-out hover:bg-transparent border-2 border-blue-2 border-solid rounded-md cursor-pointer text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-1">
                Lägg till betalning
            </button>
            <AddPaymentModal open={open} close={close} cancel={cancel} debtId={id} invoices={invoices}/>
        </>
    );
}
