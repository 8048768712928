import { PhotoIcon, UserCircleIcon, EnvelopeIcon } from '@heroicons/react/24/solid'
import { Menu, Transition } from '@headlessui/react'
import DropDown from './Dropdown'
import { useEffect, useState } from 'react';
import {validateIdNumber} from '../utilities';
import { validateRecipient } from '../debtUtilities';

export default function RecipientCreation(props) {
    const [isCompany, setIsCompany] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [recipient, setRecipient] = useState({
      name: "", type: 0, idnumber: "", email: "", 
      phone: "", street: "", zipcode: "", city: ""
  });
  const [validSate, setValidState] = useState([]);
    function test(value){
        setIsCompany(value.id == 1);
            setRecipient({
                ...recipient,
                type: value.id
            });
    }

    const handleSubmit = (event) => {
      if(!isLoaded){
        return;
      }
      event.preventDefault(); 
      let v = validateRecipient(recipient);
      setValidState(v);
      if(v.length == 0){
        afterSubmitFunction();
      }
    };
  
    const afterSubmitFunction = () => {
      if(!isLoaded){
        return;
      }
      props.notifyParent(recipient);
    };

    useEffect(()=>{
      const savedData = localStorage.getItem('debtCreationData');
      if(savedData){
        var obj = JSON.parse(savedData);
        if(!obj.recipient){
          obj.recipient ={
            name: "", type: 0, idnumber: "", email: "", 
            phone: "", street: "", zipcode: "", city: ""
        };
        }
        setRecipient(obj.recipient);
      }
      setIsLoaded(true);
    }, [])
if(!isLoaded){
  return <></>
}
  return (
    <div className="flex flex-row min-h-screen justify-center">
      <div className="mt-4">
    <h3 className="text-2xl font-semibold leading-6 text-gray-900">1. Gäldenär (steg 1/2)</h3>
  

        <form className="bg-white shadow-sm sm:rounded-xl md:col-span-2 mt-4" id="recipientform" onSubmit={handleSubmit}>
          <div className="py-6">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
                <DropDown values={[{id:0, name: "Privatperson"}, {id:1, name: "Företag"}]} notifyParent={test} header="Typ av gäldenär" value={recipient.type}></DropDown>
              </div>
              <div className="sm:col-span-3">
                <DropDown values={[{id:0, name: "Sverige"}]} header="Land"></DropDown>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Namn
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    required={true}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                        setRecipient({
                            ...recipient,
                            name: e.target.value
                        });
                    }}
                    value={recipient.name}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                  {isCompany ? "Organisationsnummer" : "Personnummer"}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="idnumber"
                    id="idnumber"
                    required={true}
                    autoComplete="idnumber"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                        setRecipient({
                            ...recipient,
                            idnumber: e.target.value
                        });
                    }}
                    value={recipient.idnumber}
                  />
                </div>
                {validSate.filter(v => v.id === "idnumber").map((m) => {
  return <span className='text-red-500'>{m.message}</span>
})}
              </div>

              <div className="sm:col-span-3">
      <label htmlFor="E-post" className="block text-sm font-medium leading-6 text-gray-900">
        E-post
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="email"
          name="E-post"
          id="E-post"
          required={false}
          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
          placeholder="you@example.com"
          onChange={(e) => {
            setRecipient({
                ...recipient,
                email: e.target.value
            });
        }}
        value={recipient.email}
        />
    </div>
              </div>

              <div className="sm:col-span-3">
              <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
        Telefon
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor="country" className="sr-only">
            Land
          </label>
          <select
          disabled={true}
            id="phone-code"
            name="phone-code"
            required={true}
            autoComplete="phone-code"
            className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm"
          >
            <option>SE</option>
          </select>
        </div>
        <input
          type="text"
          name="phone-number"
          id="phone-number"
          required={false}
          className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
          placeholder="+46 123 456 78"
          onChange={(e) => {
            setRecipient({
                ...recipient,
                phone: e.target.value
            });
          }}
          value={recipient.phone}
          />
      </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                  Adress
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    required={true}
                    autoComplete="street-address"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                        setRecipient({
                            ...recipient,
                            street: e.target.value
                        });
                    }}
                    value={recipient.street}
                  />
                </div>
              </div>

              <div className="sm:col-span-3 sm:col-start-1">
                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                  Postnummer
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="zipcode"
                    id="zipcode"
                    required={true}
                    autoComplete="postal-code"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                        setRecipient({
                            ...recipient,
                            zipcode: e.target.value
                        });
                    }}
                    value={recipient.zipcode}
                  />
                </div>
                {validSate.filter(v => v.id === "zipcode").map((m) => {
  return <span className='text-red-500'>{m.message}</span>
})}
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                  Ort
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    required={true}
                    autoComplete="address-level1"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                        setRecipient({
                            ...recipient,
                            city: e.target.value
                        });
                    }}
                    value={recipient.city}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 py-4">
            <button type='submit'
              className="block float-right items-center justify-center px-8 py-3 mb-10 text-m font-semibold text-center hover:text-blue-2 bg-blue-2 align-middle transition-all duration-100 ease-in-out hover:bg-transparent border-2 border-blue-2 border-solid rounded-md cursor-pointer text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-1"
            >
              Nästa
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
