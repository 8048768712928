import { useState, useEffect } from "react";
import {formatNumberWithSeparators} from '../utilities';
import { cardio } from 'ldrs'
import useCreadyApi from "../utils/Api";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showStats, setShowStats] = useState(false);
  const api = useCreadyApi();
  
function fetchStats(){
  setIsLoading(true);
  setShowStats(true);
}

  useEffect(() => {
    const fetchData = async () => {
      if(!showStats){
        setIsLoading(false);
        return;
      }
      try {
        

    const responseData = await api.clients.summary();
    setData(responseData);
    setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [showStats])
  if(isLoading){

cardio.register()
return(
<div className="h-screen flex items-center justify-center -mt-16">
<l-cardio
  size="150"
  stroke="12"
  speed="3.2" 
  color="black" 
></l-cardio>
</div>
)
  }
  else if(!showStats){
    return (
      <div className="h-screen flex items-center justify-center -mt-16">
          <button onClick={() => fetchStats()}>visa</button>
      </div>
    )
  }
  return (
    <div>
    <div className="w-4/5 mx-auto">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Dashboard
      </h2>
      <div>
    <dl className="mx-auto grid grid-cols-1 gap-px bg-white sm:grid-cols-2 lg:grid-cols-4">
      {data.summaryItems.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
        >
          <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {(stat.name === "Totalt " || stat.name === "Öppna" || stat.name === "Stängda") ? stat.value :formatNumberWithSeparators(stat.value)}
          </dd>
        </div>
      ))}
    </dl>
    </div>
    </div>
    </div>
  )
}
