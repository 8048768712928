import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon, FaceSmileIcon, UserIcon, QueueListIcon, PlusCircleIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import useCreadyApi from '../utils/Api'


  export default function NotificationList() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

    const api = useCreadyApi()
    useEffect(() => {
        const fetchData = async () => {
          try {
        const parsedResponse = await api.notification.list();
        setData(parsedResponse);
        setIsLoading(false)
        console.log(parsedResponse)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    
      }, []);
    if(isLoading){
        return (<></>)
    }
    return (
        <Menu as="div" className="relative ml-4 flex-shrink-0">
        <div className='flex flex-row'>
          <Menu.Button className="relative p-2.5 flex rounded-full bg-white text-sm focus:outline-none">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">Open notification menu</span>
            <BellIcon className='h-6'></BellIcon>

          </Menu.Button>
          <span className='py-2.5 pr-2.5'>{data.filter(notification => !notification.read).length}</span>
          

        </div>
        {data.length === 0 ? <></> : 

          <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-96 origin-top-right max-h-96 overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {({ active }) => (
                <ul role="list" className="divide-y divide-gray-100">
                {data.map((notification) => (
                  <li key={notification.id} className={`flex items-center justify-between gap-x-6 p-4 ${!notification.read ? 'bg-slate-100': ''}`}>
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3">
                        <p className="text-xs font-semibold leading-6 text-gray-900">{notification.message}</p>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          {new Date(notification.created).toLocaleDateString('sv-SE')}
                        </p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                          <circle r={1} cx={1} cy={1} />
                        </svg>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <a
                        href={notification.actionUri}
                        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                        Läs
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </Menu.Items>
        </Transition>
        }
        </Menu>
    )
  }
  