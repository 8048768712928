import React from "react";
import { useEffect } from "react";
import { Fragment, useState } from 'react'
import { Dialog, Listbox, Menu, Transition } from '@headlessui/react'
import { Table } from 'flowbite-react';
import { useAuth0 } from "@auth0/auth0-react";
import {useParams} from 'react-router-dom';
import { cardio } from 'ldrs';

import {
  Bars3Icon,
  CalendarDaysIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  UserCircleIcon,
  XMarkIcon as XMarkIconMini,
} from '@heroicons/react/20/solid'
import { BellIcon, XMarkIcon as XMarkIconOutline } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import PaymentTable from "../components/PaymentTable";
import InvoiceList from "../components/InvoiceList";
import PaymentList from "../components/PaymentList";
import FileList from "../components/FileList";
import useCreadyApi from "../utils/Api";
import WithdrawDebtButton from "../components/WithdrawDebtButton";
import ErrorPage from "../components/NotFound";
import Chat from "../components/Chat";

  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
export default function Debt() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(true);
  const [data, setData] = useState({});
  const params = useParams();
  const api = useCreadyApi();
  useEffect(() => {
    const fetchData = async () => {
      try {
    const responseData = await api.debts.summary(params.id);
    if(responseData === null){
      setIsError(true);
    }else{
      setIsError(false);
      setData(responseData);
    }
    setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, []);
  if(isLoading){

    cardio.register()
    return(
    <div className="h-screen flex items-center justify-center -mt-16">
    <l-cardio
      size="150"
      stroke="12"
      speed="3.2" 
      color="black" 
    ></l-cardio>
    </div>
    )
  }

  if(!isLoading && isError){
return(
<ErrorPage/>
    )
  }

      return (
        <>
      <main className="pb-16">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {/* Invoice summary */}
            <div className="lg:col-start-3 lg:row-end-1">
            <PaymentTable grid={data.grid}></PaymentTable>

            </div>

            {/* Invoice */}
            <div className="-mx-py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-8">
            <div className="flex flex-row items-center">
  <h2 className="text-2xl font-bold leading-7 text-black sm:truncate sm:text-3xl sm:tracking-tight">Ärende: {data.debtCaseNumber}</h2>
  <div className="flex-grow"></div>
  <WithdrawDebtButton></WithdrawDebtButton>
</div>
              <dl className="grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4">
                  <dd className="inline text-gray-700">
                  </dd>
                </div>
                <div className="mt-2 sm:mt-0 sm:pl-4">
             
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">Gäldenär</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="text-gray-900">{data.recipient.name}</span>
                    {data.recipient.careOfAddress ? <br />: <></>}
                    {data.recipient.careOfAddress ? (data.recipient.careOfAddress) : <></>}
                    <br />
                    {data.recipient.street}
                    <br />
                    {data.recipient.zipCode} {data.recipient.city}
                    <br />
                    {data.recipient.identityNumber}
                    <br />
                    {data.recipient.email}
                    <br />
                    {data.recipient.phoneNumber}
                  </dd>
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">Status</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">Ärende aktivt: </span>{data.status.isCompleted ? 'Nej' : 'Ja'}<br/>
                    <span className="font-medium text-gray-900">Huvudstatus: </span>{data.status.statusValue}<br/>
                    <span className="font-medium text-gray-900">Understatus: </span>{data.status.substatus}<br/>
                    <span className="font-medium text-gray-900">Betalningsstatus: </span>{data.status.payment}<br/>
                    
                  </dd>
                </div>
              </dl>
              <div>
                <InvoiceList invoices={data.invoices}></InvoiceList>
              </div>
            </div>

            <div className="lg:col-start-3 pl-2">
              {/* Activity feed */}
              <h2 className="text-m font-semibold leading-6 text-gray-900">Åtgärder</h2>
              <ul role="list" className="mt-6 space-y-6">
                {data.activities.map((activityItem, activityItemIdx) => (
                  <li key={activityItem.id} className="relative flex gap-x-4">
                    <div
                      className={classNames(
                        activityItemIdx === data.activities.length - 1 ? 'h-6' : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center'
                      )}
                    >
                      <div className="w-px bg-gray-200" />
                    </div>
                      <>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                          
                        </div>
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-medium text-gray-900">cready</span>{': '}
                          <span className="font-medium text-gray-900"> {activityItem.name}</span>
                        </p>
                        <time
                          dateTime={activityItem.days}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {new Date(activityItem.date).toLocaleDateString("sv-SE")}
                        </time>
                      </>
                  </li>
                ))}
              </ul>

<Chat debtId={params.id}></Chat>
<h2 className="text-m font-semibold leading-6 text-gray-900 pt-4">Dokument</h2>

<FileList documents={data.documents}/>
            </div>
          </div>

        </div>
        <div className="pl-8 pr-16 pt-8">
        <div className="shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-8 xl:pb-20 xl:pt-8">

        <PaymentList payments={data.payments} invoices={data.invoices}/>
</div>
</div>
      </main>
    </>
      );
};