import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, XMarkIcon, FaceSmileIcon, UserIcon, QueueListIcon, PlusCircleIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import { useAuth0 } from "@auth0/auth0-react";
import ClientDropdown from './ClientDropdown';
import useCreadyApi from '../utils/Api';
import NotificationList from './NotificationList';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header({ apiService }) {
  const { user, logout } = useAuth0();
  const [userClients, setuserClients] = useState(null);
  const [currentClient, setCurrentClient] = useState([]);
  const api = useCreadyApi();

  useEffect(() => {
    const currentClient = JSON.parse(localStorage.getItem('currentClient'));
    if (currentClient) {
      setCurrentClient(currentClient);
    }
    const userClients = JSON.parse(localStorage.getItem('userClients'));
    if(userClients){
      setuserClients(userClients);
    }
    
  }, []);


  const hasChangedClient = (client) => {
    localStorage.setItem('currentClient', JSON.stringify(client));
    window.location.href = '/';
  }
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-end py-3">
                  <img src='../logo.png' className='w-24'></img>
                </div>
                <div className="hidden lg:pl-10 lg:flex">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {/* <a
                    href="/"
                    className={classNames(
                        window.location.pathname === "/" ? 'inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900' :
                        'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700')}
                  >
                    Dashboard
                  </a> */}
                  <div className={classNames(
                      (window.location.pathname === "/" || window.location.pathname.startsWith("/debt")) ? 'inline-flex items-end border-b-4 border-blue-2 text-sm font-semibold text-gray-900 cursor-pointer' :
                        'inline-flex items-end border-b-4 border-transparent text-sm font-medium text-gray-900 hover:border-gray-300 hover:text-gray-700 cursor-pointer')}>
                 
                  <a
                    href="/"
                 className="px-6 py-3 inline-flex items-center"><QueueListIcon className='h-5 w-5 mr-2' aria-hidden="true"></QueueListIcon>
                    Ärenden
                  </a></div>
                  <div className={classNames(
                      (window.location.pathname.startsWith("/invoice")) ? 'inline-flex items-end border-b-4 border-blue-2 text-sm font-semibold text-gray-900 cursor-pointer' :
                        'inline-flex items-end border-b-4 border-transparent text-sm font-medium text-gray-900 hover:border-gray-300 hover:text-gray-700 cursor-pointer')}>
                 
                  <a
                    href="/invoices"
                 className="px-6 py-3 inline-flex items-center"><DocumentTextIcon className='h-5 w-5 mr-2' aria-hidden="true"></DocumentTextIcon>
                    Fakturor
                  </a></div>
                  <div className={classNames(
                      window.location.pathname === "/create" ? 'inline-flex items-end border-b-4 border-blue-2 text-sm font-semibold text-gray-900 cursor-pointer' :
                        'inline-flex items-end border-b-4 border-transparent text-sm font-medium text-gray-900 hover:border-gray-300 hover:text-gray-700 cursor-pointer')}>
                  <a className='px-6 py-3 inline-flex items-center'
                    href="/create"
                  ><PlusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true"></PlusCircleIcon>
                  Skapa nytt ärende
                </a></div>
              </div>
            </div>

            <div className="flex items-center lg:hidden">
              {/* Mobile menu button */}
              <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black-500">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>

            <div className="flex items-center hidden lg:ml-4 lg:flex lg:items-center mt-2">
              {/* <div className='mr-8 flex flex-row gap-2 hover:bg-slate-100 p-2 rounded-md hover:cursor-pointer'>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
                <span>4</span>
              </div> */}
              <NotificationList></NotificationList>

              {userClients ? <ClientDropdown data={userClients} parentNotify={hasChangedClient} isMultiple={userClients.length > 1}></ClientDropdown> : <></>}

              {/* <button
                  type="button"
                  className="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-black-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-4 flex-shrink-0">
                <div>
                  <Menu.Button className="relative p-2.5 flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-black-500 focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <UserIcon className='h-6'></UserIcon>

                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => logout()}
                          href="#"
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          Logga ut
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

      <Disclosure.Panel className="lg:hidden">
        <div className="space-y-1 pb-3 pt-2">
          {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
          <Disclosure.Button
            as="a"
            href="#"
            className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
          >
            Dashboard
          </Disclosure.Button>
          <Disclosure.Button
            as="a"
            href="#"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
          >
            Ärenden
          </Disclosure.Button>
          <Disclosure.Button
            as="a"
            href="#"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
          >
            Fakturor
          </Disclosure.Button>
        </div>
        <div className="border-t border-gray-200 pb-3 pt-4">
          <div className="flex items-center px-4">
            <div className="flex-shrink-0">
              <FaceSmileIcon className='h-8'></FaceSmileIcon>
            </div>
            <div className="ml-3">
              <div className="text-sm font-medium text-gray-500">{user.name}</div>
            </div>
            <button
              type="button"
              className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-black-500 focus:ring-offset-2"
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-3 space-y-1">
            <Disclosure.Button
              onClick={() => logout()}
              as="a"
              href="#"
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >
              Logga ut
            </Disclosure.Button>
          </div>
        </div>
      </Disclosure.Panel>
    </>
  )
}
    </Disclosure >
  )
}