import { useEffect, useCallback } from 'react';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import RecipientCreation from '../components/RecipientCreation'
import { useState } from 'react';
import DebtCreator from '../components/DebtCreator';

export default function DebtCreation() {
    const [step, setStep] = useState(0);
    const [data, setData] = useState({
        recipient: {},
        debts: []        
    });

    // Function to handle the submission of recipient details
    const recipientSubmitted = useCallback((s) => {
        const newData = {...data, recipient: s};
        setData(newData);
        setStep(1);
        localStorage.setItem('debtCreationData', JSON.stringify(newData));
        localStorage.setItem('debtCreationStep', '1');
    }, [data]);

    useEffect(() => {
        const savedData = localStorage.getItem('debtCreationData');
        const savedStep = localStorage.getItem('debtCreationStep');
        if (savedData && savedStep) {
            setData(JSON.parse(savedData));
            setStep(parseInt(savedStep));
        }
    }, []);

    const back = useCallback((s) => {
        const newData = {...data, debts: s};
        setData(newData);
        setStep(0);
        localStorage.setItem('debtCreationData', JSON.stringify(newData));
        localStorage.setItem('debtCreationStep', '0');
    }, [data]);

    const resetForm = function(){
        setData({
            recipient: {},
            debts: []        
        })
        setStep(0)
        localStorage.setItem('debtCreationData', JSON.stringify({
            recipient: {},
            debts: []        
        }));
        localStorage.setItem('debtCreationStep', '0');
        localStorage.removeItem('debts');
    }

    // useEffect(() => {
    //     const unloadCallback = (event) => {
    //       event.preventDefault();
    //       event.returnValue = "";
    //       return "";
    //     };
      
    //     window.addEventListener("beforeunload", unloadCallback);
    //     return () => window.removeEventListener("beforeunload", unloadCallback);
    //   }, []);

    return (
        <div>        
            <div className='relative mx-auto w-full max-w-container px-4 sm:px-6 lg:px-8'>
            <button
            onClick={() => resetForm()}
            type="button"
            className="block items-center justify-center justify-center px-8 py-3 text-m font-semibold text-center text-red hover:bg-red align-middle transition-all duration-100 ease-in-out bg-transparent border-2 border-red border-solid rounded-md cursor-pointer hover:text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
         >
            Avbryt
          </button>
            {step === 0 && <RecipientCreation notifyParent={recipientSubmitted}/>}
            {step === 1 && <DebtCreator notifyParent={back}/>}
        </div>
        </div>

    );
}
