import { formatNumberWithSeparators } from "../utilities"
import LoadingTable from "./LoadingTable";

export default function DebtList(data){
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

      function setPaidClasses(debt){
        let paidClassName = "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset";
    
        if (debt.paidPercent === 100) {
          paidClassName += " bg-green-50 text-green-700 ring-green-600/20";
        } else if (debt.paidPercent > 0 && debt.paidPercent < 100) {
          paidClassName += " bg-yellow-50 text-yellow-700 ring-yellow-600/20";
        } else {
          paidClassName += " bg-red-50 text-red-700 ring-red-600/20";
        }
    
        return paidClassName;
      }

    if(data.isLoading){
    return(
    <LoadingTable size={10}></LoadingTable>
    )

    }
    if(!data.isLoading && data.debtList.total === 0){
        return (  
        <div className="flex justify-center my-6">
        <h3 className="text-center font-extrabold text-2xl">Inga ärenden kunde hittas</h3>
      </div>
      )
    }
    return(
        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300 ">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Ärendenummer
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Namn
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Inkassodatum
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Betalt
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">
                  Summa
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.debtList.tableRowDtos.map((debt, planIdx) => (
                <tr key={debt.debtCaseNumber}>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-transparent',
                      'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                    )}
                  >
                    <div className="font-medium text-gray-900">
                      {debt.debtCaseNumber}
                    </div>
                    <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                      <span className="hidden sm:inline">·</span>
                      <span>{debt.storage}</span>
                    </div>
                    {planIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" /> : null}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {debt.recipientName}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {debt.debtSentDate}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                      <span className={setPaidClasses(debt)}>
                        {debt.paidPercent}%
                      </span>
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-gray-200',
                      'px-3 py-3.5 text-sm text-gray-500 text-right'
                    )}
                  >
                    <div>{formatNumberWithSeparators(debt.total)}</div>
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? '' : 'border-t border-transparent',
                      'relative py-3 pl-6 pr-4 text-right text-sm font-medium sm:pr-3'
                    )}
                  >
                    <a href={"/debts/" + debt.debtId}>

                    <button
                      type="button"
                      className="block w-full items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-2 shadow-sm ring-1 ring-inset ring-blue-2 hover:bg-neutral-500 hover:bg-opacity-5 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      >
                      Öppna<span className="sr-only">, {debt.name}</span>
                    </button>
                      </a>
                    {planIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" /> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    )
}