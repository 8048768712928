import { useEffect, useState } from "react";
import AddPaymentModal from "./AddPaymentModal";
import useCreadyApi from "../utils/Api";
import { cardio } from 'ldrs';
import { useNavigate, useParams } from 'react-router-dom';
import WithdrawDebtModal from "./WithdrawDebtModal";
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid'

export default function WithdrawDebtButton() {
    const [open, setOpen] = useState(false);
    let { id } = useParams();
    cardio.register()

    const api = useCreadyApi();
    const navigate = useNavigate();

    async function close() {
        setOpen(false);
        
    }

    function cancel() {
        setOpen(false);
    }

    return (
        <>
            <button onClick={() => setOpen(true)}
                    type="button"
                    className="inline-flex items-center justify-center justify-center px-6 py-2.5 text-sm font-semibold text-center text-red hover:bg-red align-middle transition-all duration-100 ease-in-out bg-transparent border-2 border-red border-solid rounded-md cursor-pointer hover:text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black">
                Återkalla ärende
            </button>
            <WithdrawDebtModal open={open} close={close} cancel={cancel} debtId={id}/>
        </>
    );
}
