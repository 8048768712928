import { useEffect, useState } from "react"
import AddDebtRow from "./AddDebtRow";
import { validateBeforeSend, validateDebt } from "../debtUtilities";
import useCreadyApi from "../utils/Api";
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline'


  
  export default function DebtCreator(props) {
  var debtsJson = localStorage.getItem('debts');
  let debts = null;
  if(debtsJson != "undefined"){
    debts = JSON.parse(debtsJson);
  }
if(debts == null){
  debts = [];
}

try{
  debts.map(m => m);
 }catch{
   debts = [];
 }
 
    const [debtsws, setDebts] = useState(debts);
    const [isAdding, setIsAddning] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingDebt, setEditingDebt] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const navigate = useNavigate();

    const api = useCreadyApi();

    function rowChanged(cancelled, debt){
        if(!cancelled){
            debtsws[debt.id] = debt;
            setDebts(debtsws);
            localStorage.setItem('debts', JSON.stringify(debtsws));
        }
        setIsAddning(false);
        setEditingDebt(null);
        setIsEditing(false);

    }

    function startEditing(debtId){
        setEditingDebt(debtsws[debtId]);
        setIsEditing(true);
    }

    async function validateAndSend(){
        const savedData = localStorage.getItem('debtCreationData');
        const debts = localStorage.getItem('debts');
        if(!debts || debts.length === 0){
          alert("Du måste minst lägga till en skuld");
        }
        else if(savedData){
          var obj = JSON.parse(savedData);
          obj.debts = JSON.parse(debts);
          var data = validateBeforeSend(obj);
          var res = await api.debts.create(data.data);
          if(res.id){
            localStorage.setItem('debtCreationData', JSON.stringify({}));
            localStorage.setItem('debts', JSON.stringify({}));
            localStorage.setItem('debtCreationStep', '0');
            navigate('/debts/' + res.id);
          }
  }
}
    return (
      <div className="px-4 sm:px-6 lg:px-8 w-4/7">
                 <button
            onClick={() => props.notifyParent(true)}
            type="button"
            className="block rounded-md border-2 border-darkgrey px-5 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-neutral-500 hover:bg-opacity-5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {"<- Tillbaka"}
          </button>
        <div className="mt-6 sm:flex sm:items-center">
          
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">2. Skulder (steg 2/2)</h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            
          <button
            onClick={() => setIsAddning(true)}
            type="button"
            className="inline-flex items-center justify-center justify-center px-8 py-3 text-m font-semibold text-center text-blue-2 hover:bg-blue-2 align-middle transition-all duration-100 ease-in-out bg-transparent border-2 border-blue-2 border-solid rounded-md cursor-pointer hover:text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-1"
          > <PlusIcon stroke="currentColor" className="h-5 w-5 mr-1.5" aria-hidden="true"></PlusIcon>
            Lägg till skuld
          </button>
          </div>
        </div>
        <div className="-mx-4 mt-8 flow-root sm:mx-0">
          <table className="min-w-full">
            <colgroup>
              <col className="w-full sm:w-1/2" />
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
            </colgroup>
            <thead className="border-b border-gray-300 text-gray-900">
              <tr>
                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 ">
                  Faktura
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-sm text-left font-semibold text-gray-900 sm:table-cell"
                >
                  Fakturadatum
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-sm text-left font-semibold text-gray-900 sm:table-cell"
                >
                  Förfallodatum
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Kapitalbelopp (inkl. moms)
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-sm font-semibold text-gray-900 sm:table-cell">
                  Påminnelse
                </th>
              </tr>
            </thead>
            <tbody>
              {debts.map((debt) => (
                <tr key={debt.id} className="border-b border-gray-200 hover:bg-zinc-100 hover:cursor-pointer" onClick={() => startEditing(debt.id)}>
                  <td className="max-w-0 py-5 pr-3 text-sm">
                    <div className="font-medium text-gray-900">{debt.invoiceid}</div>
                    <div className="mt-1 truncate text-gray-500">{debt.description}</div>
                  </td>
                  <td className="hidden px-3 py-5 text-sm text-gray-500 sm:table-cell">{debt.invoiceDate}</td>
                  <td className="hidden px-3 py-5 text-sm text-gray-500 sm:table-cell">{debt.dueDate}</td>
                  <td className="hidden px-3 py-5 text-sm text-gray-500 sm:table-cell">{debt.capital}</td>
                  <td className="hidden px-3 py-5 text-sm text-gray-500 sm:table-cell">{debt.reminder}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                >
                  Totalt
                </th>
                <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                  Totalt
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">{debts.reduce((acc, item) => acc + parseFloat(item.capital), parseFloat(0))} SEK</td>
              </tr>
            </tfoot>
          </table>
        </div>
        {isAdding ? <AddDebtRow notifyParent={rowChanged} id={debtsws.length}></AddDebtRow> : <></>}
        {(isEditing && editingDebt != null) ? <AddDebtRow notifyParent={rowChanged} debt={editingDebt}></AddDebtRow> : <></>}
     

<div className="mt-20">
<button
            onClick={() => validateAndSend()}
            type="button"
            className="block float-right items-center justify-center px-8 py-3 mb-10 text-m font-semibold text-center hover:text-blue-2 bg-blue-2 align-middle transition-all duration-100 ease-in-out hover:bg-transparent border-2 border-blue-2 border-solid rounded-md cursor-pointer text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-1"
         >
            Skicka in ärende
          </button>
      </div>
</div>

      
    )
  }