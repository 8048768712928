import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';
import config from './config';

const root = createRoot(document.getElementById('root'));
const environment = process.env.REACT_APP_NODE_ENV || 'development';
const auth0config = config[environment].auth0;

root.render(
<Auth0Provider
    domain={auth0config.domain}
    clientId={auth0config.clientId}
    authorizationParams={auth0config.authorizationParams}
    useRefreshTokens = {auth0config.useRefreshTokens}
    cacheLocation={auth0config.cacheLocation}
  >
      <App />
  </Auth0Provider>,
);