function validateIdNumber(pnr, type){
    let result = false;
    if(type==0){
        result = validatePersonnummer(pnr);
    }else{
        result = validateOrganisationNumber(pnr);
    }

    return result;
}  
  
  /**
     * Validate a 10 digit swedish personnummer
     * @param pnr
     * @returns {boolean|boolean}
     */
function validatePersonnummer(pnr) {
    var personummer = cleanDigitString(pnr);
    if (personummer.length > 12) {
        return false;
    }
    if(personummer.length === 12){
      personummer = personummer.substring(2,personummer.length);
    }

    return isValidLuhn(personummer) && isPersonnummerDate(personummer);
}


function validateOrganisationNumber(orgnr) {
    var orgnumber = cleanDigitString(orgnr);

    if (orgnumber.length < 10 || orgnumber.length > 12 || orgnumber.length == 11) {
        return false;
    }

    return isValidLuhn(orgnumber);
}


/**
 * Remove any non digit characters
 * @param digitString
 * @returns {*}
 */
function cleanDigitString(digitString) {
    return digitString.replace(/\D/g, '');
}


/**
 * Check if date is valid for personnummer
 * @param pnr
 * @returns {boolean}
 */
function isPersonnummerDate(pnr) {
    var year = parseInt(pnr.substring(0, 2));
    var month = parseInt(pnr.substring(2, 4));
    var day = parseInt(pnr.substring(4, 6));

    // Check year and month values
    if (year < 0 || year > 99 || month < 0 || month > 12) {
        return false;
    }

    var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
        daysInMonth[1] = 29;
    }

    // Check that day is within range
    var dayIsValid = day > 0 && day <= daysInMonth[month - 1];

    // If day is outside range, check if it's +60 for samordningsnummer
    if (!dayIsValid) {
        dayIsValid = day > 60 && day <= daysInMonth[month - 1] + 60;
    }

    return dayIsValid;
}

/**
 * Check if last digit of number is vald Luhn control digit
 * @param pnr
 * @returns {boolean} 
 */
function isValidLuhn(pnr) {
    var number;
    var checksum = 0;
    for (var i = pnr.length - 1; i >= 0; i--) {
        number = parseInt(pnr.charAt(i));
        if (i % 2 == 1) {
            checksum += number;
        } else {
            checksum += (number * 2) > 9 ? (number * 2) - 9 : number * 2;
        }
    }

    return checksum % 10 == 0;
}

function formatNumberWithSeparators(input) {
if (input === null || input === '') {
    return "----";
  }

  const inputString = input.toString();

  const normalizedInput = inputString.replace(',', '.');

  let number = parseFloat(normalizedInput);

  if (isNaN(number)) {
    return "----";
  }

  number = Math.round(number * 100) / 100;

  const formattedNumber = number.toLocaleString('sv-SE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
}

function isValidDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return false;
    } else {
      const inputDate = new Date(date).toISOString().slice(0, 10);
      const originalDate = dateString.trim().length === 10 ? dateString : date.toISOString().slice(0, 10);
      return inputDate === originalDate;
    }
  }

  function transformArrayForDropdown (array, idKey, nameKey) {
    return array.map(item => ({
      id: item[idKey],
      name: item[nameKey],
    }));
  };

  function fetchOriginalValueById (array, id) {
    return array.find(item => item.id === id);
  };

export { validateIdNumber, formatNumberWithSeparators, isValidDate, transformArrayForDropdown, fetchOriginalValueById };