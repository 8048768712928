import { isValidDate, validateIdNumber } from "./utilities";

function validateBeforeSend(data){
    var result = {
        data: {},
    }
    result.data.isCorporation = data.recipient.type == 1;
    result.data.identificationNumber = data.recipient.idnumber;
    result.data.name = data.recipient.name;
    result.data.email = data.recipient.email;
    result.data.phone = data.recipient.phone;

    result.data.address = {};
    result.data.address.address1 = data.recipient.street;
    result.data.address.zipCode = data.recipient.zipcode;
    result.data.address.countryCode = "SE";
    result.data.address.city = data.recipient.city;
    
    result.data.debts = [];
    data.debts.forEach((debtmodel) => {
        var debt = {};
        debt.invoiceNumber = debtmodel.invoiceid;
        debt.description = debtmodel.description;
        debt.invoiceDate = debtmodel.invoiceDate;
        debt.dueDate = debtmodel.dueDate;
        if(!debtmodel.reminder){
            debt.reminderDate = null;
        }else{
            debt.reminderDate = debtmodel.reminder;
        }
        debt.amount = debtmodel.capital;

        debt.payments = [];
        debtmodel.payments.forEach((paymentModel) => {
            debt.payments.push({

                amount: paymentModel.amount,
                transactionDate: paymentModel.tranctionDate,
                bookedDate: paymentModel.bookedDate
            })
        })

        result.data.debts.push(debt);
    });

    return result;

}


function validateDebt (debt){
    var result = {};
    result.valid = false;
    result.messages = [];

    if(debt.invoiceid.trim().length < 1){
        result.messages.push({id: "invoiceid", message: "Fyll i fakturanummer"});
    }
    if(debt.description.trim().length < 1){
        result.messages.push({id: "description", message: "Fyll i beskrivning"});
    }
    if(!isValidDate(debt.invoiceDate)){
        result.messages.push({id: "invoiceDate", message: "Ogiltigt datum"});
    }

    if(debt.reminder && !isValidDate(debt.reminder)){
        result.messages.push({id: "reminder", message: "Ogiltigt datum"});
    }

    if(debt.capital < 1){
        result.messages.push({id: "capital", message: "Kapitalbelopp måste vara minst 1"});
    }

    if(debt.dueDate < debt.invoiceDate){
        result.messages.push({id: "invoiceDate", message: "Fakturadatum måste vara före förfallodatum"});
    }

    if(result.messages.length == 0){
        result.valid = true;
    }

    for(let i = 0; i<debt.payments.length; i++){
        if(debt.payments[i].amount < 1){
            result.messages.push({id: "amount_" + i, message: "Kapitalbelopp måste vara minst 1"});
        }
        if(!isValidDate(debt.payments[i].tranctionDate)){
            result.messages.push({id: "transactionDate_" + i, message: "Ogiltigt datum"});
        }
        if(!isValidDate(debt.payments[i].bookedDate)){
            result.messages.push({id: "bookedDate_" + i, message: "Ogiltigt datum"});
        } 
        if(debt.payments[i].tranctionDate > debt.payments[i].bookedDate){
            result.messages.push({id: "transactionDate_" + i, message: "Fakturadatum måste vara före bokföringsdatum"});
        }
    }

    return result;
}

function validateRecipient(data){
    var res = [];
    if(!data.name || data.name.trim().length === 0){
        res.push({id: 'name', message: 'Fyll i namn'});
    }
    
    if(!data.idnumber || !validateIdNumber(data.idnumber, data.type)){
        if(data.type === 0){
            res.push({id: 'idnumber', message: 'Ogilitigt personnummer'});
        }
        if(data.type === 1){
            res.push({id: 'idnumber', message: 'Ogilitigt organisationsnummer'});
        }
    }
    if (!/^\d{5}$/.test(data.zipcode)) {        
        res.push({id: 'zipcode', message: 'Ogilitigt postnummer'});
    }

    return res;
}


function validatePayment(payment){
    var result = {};
    result.valid = false;

    result.messages = [];

    if(payment.amount < 1){
        result.messages.push({id: "amount", message: "Kapitalbelopp måste vara minst 1"});
    }
    if(!isValidDate(payment.transactionDate)){
        result.messages.push({id: "transactionDate", message: "Ogiltigt datum"});
    }
    // if(!isValidDate(payment.bookedDate)){
    //     result.messages.push({id: "bookedDate", message: "Ogiltigt datum"});
    // } 
    // if(payment.transactionDate > payment.bookedDate){
    //     result.messages.push({id: "transactionDate", message: "Fakturadatum måste vara före bokföringsdatum"});
    // }

    if(result.messages.length == 0){
        result.valid = true;
    }

    return result;
}


function getDebtActionDistributionString(status){
    switch (status) {
        case 0:
          return '-'
        case 1:
          return 'Brev'
        case 2:
          return 'Kivra'
        case 3:
          return 'Epost'
      }
}

function getDebtActionString(status) {
    switch (status) {
      case 0:
        return 'Faktura'
      case 1:
        return 'Inkasso'
      case 2:
        return 'Påminnelse'
      case 3:
        return 'Bf'
      case 4:
        return 'Manuell hantering av Kund'
      case 5:
        return 'Manuell hantering av påminnelse Kund'
      case 6:
        return 'Manuell hantering av handläggare'
      case 7:
        return 'Manuell hantering'
      case 9:
        return 'Inkasso/Denuntiation'
    }
  }

function getInvoiceSubStatusNameById(id){
    switch (id) {
        case 0:
          return 'Faktura'
        case 1:
          return 'Påminnelse'
        case 2:
          return 'Inkasso'
        case 3:
          return 'Avbetalningsplan'
        case 4:
          return 'Skuldsanering'
        case 5:
          return 'Betalningsförläggande'      
        case 6:
          return 'Verkställighet'
        case 7:
          return 'Konkurs'      
        case 8:
          return 'Bestriden'
        case 9:
          return 'Juridisk hantering'
      }
}

export { validateBeforeSend, validateDebt, validateRecipient, validatePayment, getDebtActionString, getDebtActionDistributionString, getInvoiceSubStatusNameById };