import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { validateDebt } from '../debtUtilities';
import { useForm } from "react-hook-form";
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'


export default function AddDebtRow(props) {
  const [open, setOpen] = useState(true);
  const [debt, setDebt] = useState(
    props.debt ??
    {
      id: props.id,
      invoiceid: '',
      description: '',
      invoiceDate: '',
      dueDate: '',
      capital: 0,
      reminder: '',
      payments: [

      ]
    }
  );
  const [validationResult, setValidationResult] = useState({ valid: false, messages: [] });
  const [paymentAdded, setPaymentAdded] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    var validationResult = validateDebt(debt);
    setValidationResult(validationResult);
    if (validationResult.valid) {
      afterSubmitFunction();
    }
  };

  const afterSubmitFunction = () => {
    setOpen(false);
    props.notifyParent(false, debt)
  };
  function close(cancelled) {
    setOpen(false);
    props.notifyParent(false, debt)

  }

  
  useEffect(() => {
    if (paymentAdded == undefined) {
      return;
    }
    document.getElementById("amount_" + paymentAdded).value = "";
    document.getElementById("amount_" + paymentAdded).focus();
  }, [paymentAdded]);
  function addPayment() {
    // Create a new payment object
    const newPayment = {
      amount: 0,
      tranctionDate: null,
      bookedDate: null
    };

    // Update the state to include the new payment object
    setDebt((prevDebt) => ({
      ...prevDebt,
      payments: [...prevDebt.payments, newPayment]
    }));
    setPaymentAdded(debt.payments.length ?? 0);
  };

  function removePayment(index) {
    setDebt(prevDebt => ({
      ...prevDebt,
      payments: prevDebt.payments.filter((_, i) => i !== index)
    }));
  };

  const handlePaymentChange = (index, field, value) => {
    setDebt((prevDebt) => {
      const updatedPayments = [...prevDebt.payments];
      updatedPayments[index] = {
        ...updatedPayments[index],
        [field]: value,
      };
      return { ...prevDebt, payments: updatedPayments };
    });
  };

  const [clicked, setClicked] = useState(false);

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    if (!clicked) {
      addPayment();
      setClicked(true);
    }

  };



  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">

                <div>
                  <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" id="recipientform" onSubmit={handleSubmit}>
                    <div className="px-4 py-6 sm:px-8 sm:py-6">
                      <h1 className='py-6 text-2xl font-semibold'>Fakturauppgifter</h1>
                      <p class="text-sm">Lägg till en skuld till ditt registrerade ärende nedan.</p>
                      <div className="grid mt-5 w-[672px] grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label htmlFor="invoiceid" className="block text-sm   leading-6 text-gray-900">
                            Fakturanummer
                          </label>
                          <div className=" mt-1">
                            <input
                              type="text"
                              name="invoiceid"
                              id="invoiceid"
                              autoComplete="invoiceid"
                              required={true}
                              value={debt.invoiceid}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                              onChange={(e) => {
                                setDebt({
                                  ...debt,
                                  invoiceid: e.target.value
                                })
                              }}
                            />
                            {validationResult.messages.filter(v => v.id === "invoiceid").map((m) => {
                              return <span className='text-red-500'>{m.message}</span>
                            })}
                          </div>
                        </div>                        <div className="sm:col-span-3">
                          <label htmlFor="capital" className="block text-sm   leading-6 text-gray-900">
                            Belopp inkl. moms
                          </label>
                          <div className="relative  mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            </div>
                            <input
                              value={debt.capital}
                              type="number"
                              name="capital"
                              id="capital"
                              required={true}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                              onChange={(e) => {
                                setDebt({
                                  ...debt,
                                  capital: e.target.value
                                });
                              }}
                            />
                            {validationResult.messages.filter(v => v.id === "capital").map((m) => {
                              return <span className='text-red-500'>{m.message}</span>
                            })}

                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="invoiceDate" className="block text-sm   leading-6 text-gray-900">
                            Fakturadatum
                          </label>
                          <div className="relative  mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            </div>
                            <input
                              value={debt.invoiceDate}
                              type="date"
                              name="invoiceDate"
                              id="invoiceDate"
                              required={true}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                              onChange={(e) => {
                                setDebt({
                                  ...debt,
                                  invoiceDate: e.target.value
                                });
                              }}
                            />
                            {validationResult.messages.filter(v => v.id === "invoiceDate").map((m) => {
                              return <span className='text-red-500'>{m.message}</span>
                            })}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="dueDate" className="block text-sm   leading-6 text-gray-900">
                            Förfallodatum
                          </label>
                          <div className="relative  mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            </div>
                            <input
                              value={debt.dueDate}
                              type="date"
                              name="dueDate"
                              id="dueDate"
                              required={true}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                              onChange={(e) => {
                                setDebt({
                                  ...debt,
                                  dueDate: e.target.value
                                });
                              }}
                            />
                            {validationResult.messages.filter(v => v.id === "dueDate").map((m) => {
                              return <span className='text-red-500'>{m.message}</span>
                            })}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="reminder" className="block text-sm   leading-6 text-gray-900">
                            Ev. påminnelse skickades
                          </label>
                          <div className="relative  mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            </div>
                            <input
                              value={debt.reminder}
                              type="date"
                              name="reminder"
                              id="reminder"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                              onChange={(e) => {
                                setDebt({
                                  ...debt,
                                  reminder: e.target.value
                                });
                              }}
                            />
                            {validationResult.messages.filter(v => v.id === "reminder").map((m) => {
                              return <span className='text-red-500'>{m.message}</span>
                            })}
                          </div>
                        </div>
                        <div className="sm:col-span-full">
                          <label htmlFor="description" className="block text-sm   leading-6 text-gray-900">
                            Fakturan avser
                          </label>
                          <div className="relative  mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            </div>
                            <textarea
                              value={debt.description}
                              name="description"
                              id="description"
                              maxlength="160"
                              rows="1"
                              required={true}
                              className="resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                              onChange={(e) => {
                                setDebt({
                                  ...debt,
                                  description: e.target.value
                                });
                              }}
                            ></textarea>
                            <div class="flex items-center mt-5">
                              <input
                                id="checkboxId"
                                name="checkboxName"
                                type="checkbox"
                                className="h-5 w-5 flex-shrink-0 rounded border-gray-300 text-blue-1 focus:ring-black"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                              <label htmlFor="checkboxId" className="ml-2 flex-1 text-sm">
                                Jag har mottagit delbetalning av fakturan
                              </label>
                            </div>
                            {validationResult.messages.filter(v => v.id === "description").map((m) => {
                              return <span className='text-red-500'>{m.message}</span>
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isChecked ? (<div className="px-4 pb-6 sm:px-8 sm:pb-6">

                      <h2 className='py-4 text-lg font-semibold'>Betalningar</h2>
                      <div className="grid w-[672px] grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7"></div>
                      <p class="text-sm">Lägg till eventuella delbetalningar här.</p>
                      {debt.payments.map((payment, index) => (
                        <>
                          <div className="grid mt-3 w-[672px] grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
                            <div key={index} className='sm:col-span-2'>
                              <label htmlFor="amount" className="block text-sm   leading-6 text-gray-900">
                                Belopp
                              </label>
                              <div className="relative  mt-1 rounded-md shadow-sm">
                                <input
                                  value={payment.amount}
                                  onChange={(e) => handlePaymentChange(index, 'amount', e.target.value)}
                                  type="number"
                                  name="amount"
                                  id={"amount_" + index}
                                  required={true}
                                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                />

                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                                    SEK
                                  </span>
                                </div>
                              </div>
                              {validationResult.messages.filter(v => v.id === ("amount_" + index)).map((m) => {
                                return <span className='text-red-500'>{m.message}</span>
                              })}
                            </div>
                            <div className="sm:col-span-2">
                              <label htmlFor="invoiceDate" className="block text-sm   leading-6 text-gray-900">
                                Fakturadatum
                              </label>
                              <div className="relative  mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                </div>
                                <input
                                  value={payment.tranctionDate || ''}
                                  onChange={(e) => handlePaymentChange(index, 'tranctionDate', e.target.value)}
                                  type="date"
                                  name="invoiceDate"
                                  id={"invoiceDate_" + index}
                                  required={true}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                />
                                {validationResult.messages.filter(v => v.id === ("invoiceDate_" + index)).map((m) => {
                                  return <span className='text-red-500'>{m.message}</span>
                                })}
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              <label htmlFor="bookedDate" className="block text-sm   leading-6 text-gray-900">
                                Bokföringsdatum
                              </label>
                              <div className="relative  mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                </div>
                                <input
                                  value={payment.bookedDate || ''}
                                  onChange={(e) => handlePaymentChange(index, 'bookedDate', e.target.value)}
                                  type="date"
                                  name="bookedDate"
                                  id={"bookedDate_" + index}
                                  required={false}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                />
                                {validationResult.messages.filter(v => v.id === ("bookedDate_" + index)).map((m) => {
                                  return <span className='text-red-500'>{m.message}</span>
                                })}

                              </div>
                            </div>
                            <div className='sm:col-span-1'>
                              <button
                                onClick={() => removePayment(index)}
                                type="button"
                                className="rounded-full bg-transparent border-solid border-2 border-red p-1.5 text-black shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600
                                mt-[28px] hover:bg-red/[.15]"
                              >
                                <MinusIcon stroke="red" className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </>
                      ))}
                      {/* <button
                        onClick={addPayment}
                        type="button"
                        className=" mt-5 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      > */}
                      <button
                        onClick={addPayment}
                        type="button"
                        className="text-sm mt-4 flex items-center rounded-full border-solid border-2 border-green py-1.5 px-2 text-green shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 hover:bg-green-500"
                      >
                        <PlusIcon stroke="green" className="h-5 w-5" aria-hidden="true"></PlusIcon>
                        Delbetalning
                      </button>
                    </div>) : (null)}

                    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                      <button
                        onClick={() => close(true)}
                        className="block rounded-md border-2 border-darkgrey px-5 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-neutral-500 hover:bg-opacity-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                      >
                        Avbryt
                      </button>
                      <button
                        type='submit'
                        onClick={close}
                        className="inline-flex items-center justify-center px-5 py-2 text-sm font-semibold text-center hover:text-blue-2 bg-blue-2 align-middle transition-all duration-100 ease-in-out hover:bg-transparent border-2 border-blue-2 border-solid rounded-md cursor-pointer text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-1"
                      >
                        Lägg till
                      </button>

                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
